// Generated by Framer (d213f04)

import { addFonts, addPropertyControls, ControlType, cx, CycleVariantState, RichText, useLocaleInfo, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";
import * as sharedStyle from "../css/IhsN1fHGp";

const cycleOrder = ["UNdLG_c_r"];

const variantClassNames = {UNdLG_c_r: "framer-v-1yxltd4"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const transitions = {default: {damping: 60, delay: 0, mass: 1, stiffness: 500, type: "spring"}};

const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const getProps = ({height, id, title, width, ...props}) => { return {...props, Q_r01mrcm: title ?? props.Q_r01mrcm ?? "MAKING BUSINESS EASIER"} }

const createLayoutDependency = (props, variants) => variants.join('-') + props.layoutDependency

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number;title?: string; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const { activeLocale } = useLocaleInfo()

const {style, className, layoutId, variant, Q_r01mrcm, ...restProps} = getProps(props)

const {baseVariant, classNames, gestureVariant, setGestureState, setVariant, transition, variants} = useVariantState({cycleOrder, defaultVariant: "UNdLG_c_r", transitions, variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const defaultLayoutId = React.useId()

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<motion.div initial={variant} animate={variants} onHoverStart={() => setGestureState({isHovered: true})} onHoverEnd={() => setGestureState({isHovered: false})} onTapStart={() => setGestureState({isPressed: true})} onTap={() => setGestureState({isPressed: false})} onTapCancel={() => setGestureState({isPressed: false})} className={cx("framer-1WuJ4", sharedStyle.className, classNames)} style={{display: "contents"}}>
<Transition value={transition}><motion.div {...restProps} className={cx("framer-1yxltd4", className)} data-border data-framer-name={"default"} layoutDependency={layoutDependency} layoutId={"UNdLG_c_r"} ref={ref} style={{"--border-bottom-width": "1.5px", "--border-color": "rgb(132, 89, 255)", "--border-left-width": "1.5px", "--border-right-width": "1.5px", "--border-style": "solid", "--border-top-width": "1.5px", borderBottomLeftRadius: 30, borderBottomRightRadius: 30, borderTopLeftRadius: 30, borderTopRightRadius: 30, ...style}}><RichText __fromCanvasComponent children={<React.Fragment><motion.p className={"framer-styles-preset-1l1xa1n"} data-styles-preset={"IhsN1fHGp"} style={{"--framer-text-color": "var(--extracted-r6o4lv)"}}>MAKING BUSINESS EASIER</motion.p></React.Fragment>} className={"framer-1fisus1"} data-framer-name={"Text"} layoutDependency={layoutDependency} layoutId={"Ab9CbsFZM"} style={{"--extracted-r6o4lv": "var(--token-0c8fd507-faea-491a-8caf-ad6cd40c959b, rgb(40, 40, 42)) ", "--framer-paragraph-spacing": "0px"}} text={Q_r01mrcm} verticalAlignment={"center"} withExternalLayout/></motion.div></Transition>
</motion.div>
</LayoutGroup>)

});

const css = [".framer-1WuJ4 [data-border=\"true\"]::after { content: \"\"; border-width: var(--border-top-width, 0) var(--border-right-width, 0) var(--border-bottom-width, 0) var(--border-left-width, 0); border-color: var(--border-color, none); border-style: var(--border-style, none); width: 100%; height: 100%; position: absolute; box-sizing: border-box; left: 0; top: 0; border-radius: inherit; pointer-events: none; }", "@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-1WuJ4 .framer-9fe8sk { display: block; }", ".framer-1WuJ4 .framer-1yxltd4 { align-content: flex-start; align-items: flex-start; display: flex; flex-direction: row; flex-wrap: nowrap; gap: 10px; height: min-content; justify-content: flex-start; overflow: visible; padding: 4px 12px 4px 12px; position: relative; width: 190px; }", ".framer-1WuJ4 .framer-1fisus1 { flex: 1 0 0px; height: auto; position: relative; white-space: pre-wrap; width: 1px; word-break: break-word; word-wrap: break-word; }", "@supports (background: -webkit-named-image(i)) and (not (font-palette:dark)) { .framer-1WuJ4 .framer-1yxltd4 { gap: 0px; } .framer-1WuJ4 .framer-1yxltd4 > * { margin: 0px; margin-left: calc(10px / 2); margin-right: calc(10px / 2); } .framer-1WuJ4 .framer-1yxltd4 > :first-child { margin-left: 0px; } .framer-1WuJ4 .framer-1yxltd4 > :last-child { margin-right: 0px; } }", ...sharedStyle.css]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 29
 * @framerIntrinsicWidth 190
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["fixed","auto"]}}}
 * @framerVariables {"Q_r01mrcm":"title"}
 */
const FrameruL4cBLz4x: React.ComponentType<Props> = withCSS(Component, css, "framer-1WuJ4") as typeof Component;
export default FrameruL4cBLz4x;

FrameruL4cBLz4x.displayName = "badge";

FrameruL4cBLz4x.defaultProps = {height: 29, width: 190};

addPropertyControls(FrameruL4cBLz4x, {Q_r01mrcm: {defaultValue: "MAKING BUSINESS EASIER", displayTextArea: false, title: "Title", type: ControlType.String}} as any)

addFonts(FrameruL4cBLz4x, [...sharedStyle.fonts])